import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { Layout, OtherServices, OtherLocations, Slice } from '../components';

const IS_BROWSER = typeof window !== 'undefined';

const metaTitle = location => ({
  'Office Catering': `Office Catering ${location} | Corporate Catering | YORDAR`,
  'Office Snacks': `Office Snack Delivery ${location} | Easy Ordering | YORDAR`,
  'Office Milk': `Office Milk Delivery ${location} | Easy Ordering | YORDAR`,
  'Office Fruit': `Office Fruit Delivery ${location} | Easy Ordering | YORDAR`,
});

const metaDesc = location => ({
  'Office Catering': `Fast office catering by Yordar - Best corporate catering and office food delivery and suppliers in ${location}. Order your delicious food now`,
  'Office Snacks': `Fast office snack delivery by Yordar - Best office snack delivery and suppliers in ${location}. Try our easy online ordering`,
  'Office Milk': `Fresh office milk delivery by Yordar - Best office milk delivery and suppliers in ${location}. Try our easy online ordering`,
  'Office Fruit': `Fresh office fruit delivery by Yordar - Best office fruit delivery and suppliers in ${location}. Order your delicious fruit now`,
});

export const Locations = ({ location, data: staticData, pathContext = {} }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });
  const { sliceKey, category, otherAreasCovered = [] } = pathContext;
  const { page } = data;
  const { data: pageData, uid } = page;

  const [previewSliceKey, setPreviewSlideKey] = useState('body');
  const [sliceData, setSliceData] = useState(pageData[sliceKey] || []);
  const [activeCategory, setActiveCategory] = useState(category || 'Office Catering');

  const {
    state,
    suburb: suburbObj,
    catering_active: hasCatering,
    office_snacks_active: hasOfficeSnacks,
    office_milk_active: hasOfficeMilk,
    office_fruit_active: hasOfficeFruit,
  } = pageData;

  // Preview logic
  useEffect(() => {
    const sliceKeyToUse = previewData ? previewSliceKey : sliceKey;
    const categoryToUse = previewData ? getCatFromPreviewKey(previewSliceKey) : category;
    setSliceData(pageData[sliceKeyToUse]);
    setActiveCategory(categoryToUse);
  }, [previewSliceKey]);

  const getCatFromPreviewKey = key => {
    if (key === 'body1') return 'Office Snacks';
    if (key === 'body2') return 'Office Milk';
    if (key === 'body3') return 'Office Fruit';
    return 'Office Catering';
  };

  const changePreviewKey = (event, bodyKey) => {
    event.preventDefault();
    setPreviewSlideKey(bodyKey);
  };

  // SEO data
  const suburb = suburbObj.text;
  const seo = {
    title: metaTitle(suburb)[activeCategory],
    desc: metaDesc(suburb)[activeCategory],
  };

  return (
    <Layout location={location} seo={seo}>
      {previewData && (
        <nav className="preview-bar">
          <span>Select page to preview: </span>
          <a href="#officecateringpreview" onClick={event => changePreviewKey(event, 'body')}>
            Office Catering
          </a>
          <a href="#officesnackspreview" onClick={event => changePreviewKey(event, 'body1')}>
            Office Snacks
          </a>
          <a href="#officemilkpreview" onClick={event => changePreviewKey(event, 'body2')}>
            Office Milk
          </a>
          <a href="#officefruitpreview" onClick={event => changePreviewKey(event, 'body3')}>
            Office Fruit
          </a>
        </nav>
      )}
      {sliceData.map(slice => (
        <Slice key={slice.id} data={slice} locationData={{ suburb, state, category: activeCategory }} />
      ))}
      <OtherServices
        tagline={`Not looking for ${activeCategory}?`}
        title={`Other Yordar services available in ${suburb}`}
        suburb={suburb}
        suburbSlug={uid}
        category={activeCategory}
        hasCatering={hasCatering}
        hasOfficeSnacks={hasOfficeSnacks}
        hasOfficeMilk={hasOfficeMilk}
        hasOfficeFruit={hasOfficeFruit}
      />
      {otherAreasCovered && otherAreasCovered.length > 0 && (
        <OtherLocations
          tagline={`${activeCategory} near ${suburb}`}
          title={`Not based in ${suburb}? We cover the following areas`}
          otherAreas={otherAreasCovered}
        />
      )}
    </Layout>
  );
};

export default Locations;

export const locationsQuery = graphql`
  query LocationBySlug($uid: String!) {
    page: prismicLocations(uid: { eq: $uid }) {
      id
      uid
      data {
        suburb {
          text
        }
        catering_active
        office_snacks_active
        office_milk_active
        office_fruit_active
        state
        body {
          ...HeroCateringFragment
          ...ImageTextColumnsCateringFragment
          ...LogosCateringFragment
          ...SupplierListingsCateringFragment
        }
        body1 {
          ...HeroOfficeSnacksFragment
          ...ImageTextColumnsOfficeSnacksFragment
          ...LogosOfficeSnacksFragment
          ...SupplierListingsOfficeSnacksFragment
        }
        body2 {
          ...HeroOfficeMilkFragment
          ...ImageTextColumnsOfficeMilkFragment
          ...LogosOfficeMilkFragment
          ...SupplierListingsOfficeMilkFragment
        }
        body3 {
          ...HeroOfficeFruitFragment
          ...ImageTextColumnsOfficeFruitFragment
          ...LogosOfficeFruitFragment
          ...SupplierListingsOfficeFruitFragment
        }
      }
    }
  }
`;
